<script setup lang="ts">
const { t, locales, setLocale } = useI18n()
const availableLocales = computed(() => {
  return locales.value
})
</script>

<template>
  <div>
    <v-divider />
    <v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="$mdiTranslate"
          :title="t('sidebar.languages')"
        />
      </template>
      <v-list-item
        v-for="language in availableLocales"
        :key="language.code"
        :title="language.name"
        slim class="border-t"
        @click.prevent.stop="setLocale(language.code)"
      />
    </v-list-group>
  </div>
</template>