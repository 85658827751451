<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
const localePath = useLocalePath()
const { project } = useRuntimeConfig().public
</script>

<template>
  <v-list class="!p-0">
    <v-divider />

    <v-list-item title="Briefings" prepend-icon="$mdiListbox" :to="localePath('/briefings')" />
    <v-divider />

    <v-list-item title="Productos" prepend-icon="$mdiDashboard" :to="localePath('/products')" />
    <v-divider />

    <v-list-item title="Recomendaciones" prepend-icon="$mdiAI" :to="localePath('/insights')" />
    <v-divider />

    <template v-if="project === 'siroko-fsbd'">
      <v-list-item title="FSBD Adify" prepend-icon="$mdiAIoutline" :to="localePath('/adify/budget')" />
      <v-divider />
      <v-list-item v-if="isSuperadmin || isAdmin" title="Savvywords" prepend-icon="$mdiSavvywords" :to="localePath('/savvywords/dashboard')" />
      <v-divider v-if="isSuperadmin || isAdmin" />
    </template>
    <template v-else>
      <v-list-item v-if="isSuperadmin" title="FSBD Adify" prepend-icon="$mdiAIoutline" :to="localePath('/adify/budget')">
        <template v-slot:append>
          <v-badge dot inline color="success" />
        </template>
      </v-list-item>
      <v-divider v-if="isSuperadmin" />
      <v-list-item v-if="isSuperadmin" title="Savvywords" prepend-icon="$mdiSavvywords" :to="localePath('/savvywords/dashboard')">
        <template v-slot:append>
          <v-badge dot inline color="success" />
        </template>
      </v-list-item>
      <v-divider v-if="isSuperadmin" />
    </template>


    <template v-if="project === 'siroko-fsbd'">
      <!--<v-list-item v-if="isSuperadmin || isAdmin" title="CC AI" prepend-icon="$mdiChat" :to="localePath('/ccai')" />
      <v-divider v-if="isSuperadmin || isAdmin" />-->
      <v-list-item v-if="isSuperadmin || isAdmin" title="Stock / Ventas" prepend-icon="$mdiWarehouse" :to="localePath('/stock/report')" />
      <v-divider v-if="isSuperadmin || isAdmin" />
    </template>

    <v-list-item v-if="isSuperadmin || isAdmin" title="Parámetros" prepend-icon="$mdiProgressCheck" :to="localePath('/parameters')" />
    <v-divider v-if="isSuperadmin || isAdmin" />

    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" :to="localePath('/analytics')" />
    <v-divider v-if="isSuperadmin || isAdmin" />
  </v-list>
</template>
