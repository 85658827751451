<script setup lang="ts">
const localePath = useLocalePath()
const { project } = useRuntimeConfig().public
</script>

<template>
  <div class="flex items-center justify-center py-3">
    <NuxtLink :to="localePath('index')" class="inline-block w-1/3">
      <img :src="`/media/${project.replace('-fsbd', '')}.svg`" alt="" />
    </NuxtLink>
  </div>
</template>