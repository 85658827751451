<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
const localePath = useLocalePath()
const { project } = useRuntimeConfig().public
</script>

<template>
  <v-list density="compact" class="!p-0">
    <LayoutLanguages v-if="project === 'devmunity-fsbd'" />
    <v-divider v-if="isSuperadmin || isAdmin" />
    <v-list-group v-if="isSuperadmin || isAdmin">
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="isSuperadmin || isAdmin ? props : null"
          :disabled="!isSuperadmin && !isAdmin"
          prepend-icon="$mdiCog"
          :title="$t('sidebar.config')"
        />
      </template>
      <v-divider />
      <v-list-item title="Scripts" prepend-icon="$mdiScript" :to="localePath('/savvywords')" />
      <v-divider />
      <v-list-item :title="$t('sidebar.companies')" prepend-icon="$mdiAccountTie" :to="isSuperadmin || isAdmin ? localePath('/config/companies') : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider />
      <v-list-item :title="$t('sidebar.documentation')" prepend-icon="$mdiDocuments" :to="localePath('/config/documentation')" />
      <v-divider />
      <v-list-item :title="$t('sidebar.users')" prepend-icon="$mdiAccount" :to="isSuperadmin || isAdmin ? localePath('/config/users') : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider />
      <v-list-item :title="$t('sidebar.invoices')" prepend-icon="$mdiInvoice" :to="isSuperadmin || isAdmin ? localePath('/config/invoices') : '/'" :disabled="!isSuperadmin && !isAdmin" />
    </v-list-group>
  </v-list>
</template>